/* :root {
    --color1: #DDE5B4;
    --color2: #FFD1D4;
    --color3: #C7522A;
    --color4: #D7F9F8;
    --color5: #F1DD8F;
    --color6: #E5D4EF;
}

.Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.paper1 {
    position: absolute;
    filter: drop-shadow(0px 4px 4px #c7512acd);
    transition: transform 1s ease-in-out;
}



.paper1:hover {
    transform: scale(1.1);
}

.paper2 {
    position: absolute;
    filter: drop-shadow(0px 4px 4px rgba(255, 54, 54, 0.564));
    transition: transform 1s ease-in-out;
}

.paper2:hover {
    transform: scale(1.1);
}

.paper3 {
    position: absolute;
    filter: drop-shadow(0px 4px 4px rgba(255, 54, 54, 0.564));
    transition: transform 1s ease-in-out;
}

.paper3:hover {
    transform: scale(1.1);
}

.paper1.active,
.paper2.active,
.paper3.active {
    transform: scale(1.1);
} */

:root {
    --color1: #DDE5B4;
    --color2: #FFD1D4;
    --color3: #C7522A;
    --color4: #D7F9F8;
    --color5: #F1DD8F;
    --color6: #E5D4EF;
}

.Home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100vh;
    text-align: center;
}

.paper1 {
    position: absolute;
    -webkit-filter: drop-shadow(0px 4px 4px #c7512acd);
            filter: drop-shadow(0px 4px 4px #c7512acd);
    -webkit-transition: -webkit-transform 1s ease-in-out;
    transition: -webkit-transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}



.paper1:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.paper2 {
    position: absolute;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(255, 54, 54, 0.564));
            filter: drop-shadow(0px 4px 4px rgba(255, 54, 54, 0.564));
    -webkit-transition: -webkit-transform 1s ease-in-out;
    transition: -webkit-transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.paper2:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.paper3 {
    position: absolute;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(255, 54, 54, 0.564));
            filter: drop-shadow(0px 4px 4px rgba(255, 54, 54, 0.564));
    -webkit-transition: -webkit-transform 1s ease-in-out;
    transition: -webkit-transform 1s ease-in-out;
    -o-transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.paper3:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}

.paper1.active,
.paper2.active,
.paper3.active {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}